import './style.css'
import {
  Button,
  ButtonProps,
  Grid,
  Icon,
  IconProps,
  Message,
  Popup,
  PopupProps,
} from 'semantic-ui-react'
import { useAppDispatch, useAppSelector } from 'AppSrc/store'
import React from 'react'
import debug from 'debug'
import { getRefreshButtonMsg } from './helpers'
import { refreshCancel, refreshRequest, selectors as refreshSelectors } from './reducer'
import {
  refreshTypes,
  stateIndexes,
  stateIndexType,
  refreshEndpoints as refreshEndpointsDefault,
} from './types'

debug.enable('refresh/component:*')
// const log = debug('refresh/component:log')
// const info = debug('refresh/component:info')
// const error = debug('refresh/component:error')

type Props = {
  componentType: string
  idx: stateIndexType
  popupProps: PopupProps
  iconProps?: IconProps
  buttonProps?: ButtonProps
  buttonName?: string
  refreshEndpoints?: typeof refreshEndpointsDefault
}

const Refresh = ({
  componentType,
  idx,
  popupProps,
  iconProps = {},
  buttonProps = {},
  buttonName = '',
  refreshEndpoints = refreshEndpointsDefault,
}: Props) => {
  const dispatch = useAppDispatch()

  const refreshStatus = useAppSelector(state => refreshSelectors.refreshStatus(state))
  const refreshDate = useAppSelector(state => refreshSelectors.refreshDate(state))
  const errorData = useAppSelector(state => refreshSelectors.errorData(state))

  const onClickFunc = () => {
    // log('onClickFunc', idx, refreshStatus[idx])
    if (refreshStatus[idx] === 'refreshing') {
      dispatch(refreshCancel(idx))
    } else {
      const refreshType: refreshTypes =
        idx === stateIndexes.REFRESH_IDX_BUTTON_REFRESH ? 'refresh' : 'skipRefresh'
      dispatch(refreshRequest(idx, refreshType, refreshEndpoints))
    }
  }

  const { button, message } = getRefreshButtonMsg(
    idx,
    stateIndexes,
    refreshStatus,
    refreshDate,
    errorData
  )

  if (componentType === 'circular button') {
    return (
      <Popup
        hoverable
        flowing
        on={['click']}
        trigger={
          <Icon
            fitted
            loading={message.loading}
            className="refresh-circular-button"
            {...(message.text.match(/fail|cancel/) && { color: 'red' })}
            {...{ ...{ name: 'refresh' }, ...iconProps }}
          />
        }
        header={message.header}
        content={
          <div>
            <div>{message.text}</div>
            <div style={{ textAlign: 'center', marginTop: '.5em' }}>
              <Button
                size="large"
                {...buttonProps}
                onClick={onClickFunc}
                content={message.loading ? 'Cancel' : buttonName || 'Refresh'}
              />
            </div>
          </div>
        }
        {...popupProps}
      />
    )
  }

  return (
    <Grid container>
      <Grid.Column computer={4} tablet={4} mobile={16} verticalAlign="middle" textAlign="center">
        <Button onClick={onClickFunc} content={button.content[idx]} disabled={button.disabled} />
      </Grid.Column>
      <Grid.Column computer={12} tablet={12} mobile={16}>
        <Message {...message.props} icon>
          {message.loading && <Icon name="circle notched" loading={message.loading} />}
          <Message.Content>
            <Message.Header>{message.header}</Message.Header>
            <p>{message.text}</p>
          </Message.Content>
        </Message>
      </Grid.Column>
    </Grid>
  )
}

export default Refresh
