import { usePrompt } from './reactRouterDomPromptBlocker'

type Props = {
  message: string
  when: boolean
}

const Prompt = ({ message, when }: Props) => {
  usePrompt(message, when)

  return null
}

export default Prompt
